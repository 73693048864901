import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert, { AlertColor } from '@mui/material/Alert';
import Slide, { SlideProps } from '@mui/material/Slide';

interface SnackbarComponentProps {
  open: boolean;
  severity: AlertColor | undefined;
  message: string;
  onClose: () => void;
  autoHideDuration?: number;
}
const defaultAutoHideDuration = 5000;

const SnackbarComponent = (props: SnackbarComponentProps) => {
  const { open, severity, message, onClose, autoHideDuration } = props;
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration ? autoHideDuration : defaultAutoHideDuration}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      TransitionComponent={(slideProps: SlideProps) => {
        return <Slide {...slideProps} direction='down' />;
      }}
    >
      <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;
