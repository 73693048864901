import { createTheme } from '@mui/material/styles';
import { AppInstance } from 'src/core/utils/instance.utils';

const defaultTheme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
  },
  palette: {
    primary: {
      main: '#3ABBCC',
      contrastText: '#fff',
    },
    secondary: {
      main: '#F2F5FA',
      dark: '#D6DAE3',
      contrastText: '#7786A4',
    },
  },
});

const crTheme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
  },
  palette: {
    primary: {
      main: '#bf87ed',
      contrastText: '#fff',
    },
    secondary: {
      main: '#F2F5FA',
      dark: '#D6DAE3',
      contrastText: '#7786A4',
    },
  },
});

const natroxTheme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
  },
  palette: {
    primary: {
      main: 'rgb(55,114,180)',
      contrastText: '#fff',
    },
    secondary: {
      main: '#F2F5FA',
      dark: '#D6DAE3',
      contrastText: '#7786A4',
    },
  },
});

export const themes = {
  [AppInstance.IS]: defaultTheme,
  [AppInstance.EU]: defaultTheme,
  [AppInstance.UK]: defaultTheme,
  [AppInstance.SG]: defaultTheme,
  [AppInstance.SINGHEALTH]: defaultTheme,
  [AppInstance.NATROX]: natroxTheme,
  [AppInstance.CR]: crTheme,
};
