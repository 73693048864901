import { AlertColor } from '@mui/material';

export class AlertDialogProps {
  loadingAnimation?: boolean;
  open?: boolean;
  title?: any;
  content?: any;
  closeLabel?: string;
  confirmLabel?: string;

  onConfirm?: () => Promise<boolean | undefined> | boolean;

  constructor(state?: AlertDialogProps | any) {
    this.loadingAnimation = state?.loadingAnimation;
    this.open = Boolean(state?.open);
    this.title = state?.title;
    this.content = state?.content;
    this.closeLabel = state?.closeLabel;
    this.confirmLabel = state?.confirmLabel;
    this.onConfirm = state?.onConfirm;
  }
}

export class SnackBarProps {
  open: boolean;
  severity: AlertColor | undefined;
  message: string;

  constructor(state?: SnackBarProps | any) {
    this.open = false;
    this.message = '';
    if (state) {
      this.open = Boolean(state?.open);
      this.severity = state?.severity;
      this.message = state?.message;
    }
  }
}
