import './PswrdReset.style.scss';
import React, { CSSProperties, useContext, useState } from 'react';

import MainLogo from 'src/components/logo/MainLogo.component';
import LoginForm from '../login/components/LoginForm.component';
import { PageLoading } from 'src/components/loading/Loading.component';
import ResetForm from './components/ResetForm.component';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AppContext from 'src/core/context/global/AppContext';

const PasswordResetPage = () => {
  const { setSnackBarProps } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState({ password: '', confirmPassword: '' });
  const [loading, setLoading] = useState(false);
  const [successOp, setSuccessOp] = useState(false);

  const onResetPress = async () => {
    try {
      setLoading(true);
      //Write Code Here
      setSuccessOp(true);
    } catch (error) {
      setSnackBarProps({ open: true, severity: 'error', message: t('common.errorOccurred') });
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className='page reset-page tad'>
      <div className='container'>
        {loading && <PageLoading />}
        <MainLogo className='main-logo' />
        {successOp ? (
          <>
            <div className='mb-3 alert'>{'Your password was reset successfully!'}</div>
            <button style={style.btn_reset} className='btn btn-secondary' onClick={() => navigate('/login')}>
              {t('settings.signIn')}
            </button>
          </>
        ) : (
          <ResetForm data={data} setData={setData} onSubmit={onResetPress} />
        )}
      </div>
    </div>
  );
};

export default PasswordResetPage;

const style: { [style: string]: CSSProperties } = {
  btn_reset: {
    width: '100%',
    padding: '0.5rem',
  },
};
